var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-menu","title":"DANH SÁCH MENU","subTitle":"Tạo và cập nhật menu hệ thống. Chức năng dành cho nhà phát triển","useAdd":"","titleAdd":"Tạo menu"},on:{"add":_vm.createMenu}},[_c('template',{slot:"filter"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","clearable":"","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","pageCount":_vm.pageCount,"classPaging":"pt-2"},on:{"update:page":function($event){_vm.page=$event},"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parent ? item.parent.order + "-" + item.order : item.order))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editMenu(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Cập nhật")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.xoaMenu(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa Menu")],1)],1)],1)],1)]}}],null,true)}),_c('create-edit',{ref:"menuForm",on:{"on-done":_vm.getMenu}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }